@import url("https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@300&display=swap");

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #a6a6a6 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 25px;
  border: 2px solid #ffffff;
}

body {
  font-family: "Montserrat";
  padding: 0px;
  margin: 0px;
}
div.fonts {
  font-weight: 700;
}
p.login_signup {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  align-items: center;
}
p.termsH1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
  text-align: center;
}
p.termsH2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 41px;
  text-align: center;
}
p.termsH3 {
  font-style: normal;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 41px;
}
p.termsText {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  margin-top: 20px;
}
p.termsTextInner {
  font-style: normal;
  padding-left: 15px;
  font-weight: 200;
  font-size: 18px;
  margin-top: 20px;
}
p.lastTermsText{
  margin-bottom: 40px;
}
a.termsLink {
  font-size: 12px;
}
a.termsLinkPrivacy{
  font-size: 12px;
  margin-left: 10px;
}
.termAndPrivacyLink{
  margin-top: 20px;
  text-align: center;
}
div.logoDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
#divider {
  display: none;
}
a.link_login_signup {
  text-decoration: none;
  color: #828282;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
a.link_login_login {
  text-decoration: none;
  color: #009a4e;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.side_login_signup {
  visibility: hidden;
}

@media screen and (max-width: 900px) {
  #relatedQuestions {
    display: none;
  }
  div.logoDiv {
    justify-content: center;
  }
  div#divider {
    display: block !important;
    border: 2px solid #009a4e;
    width: 100vw;
    margin-top: 10px;
    margin-left: -32px;
  }
  div.links {
    display: none;
  }
  div.formlayout {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  div.head_login_signup {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a.side_login_signup {
    visibility: visible;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #828282;
    text-decoration: none;
  }
}
@media screen and (max-width: 950px) {
  .links a {
    font-size: 14px;
  }
  a.link_login_login,
  a.link_login_signup {
    margin-left: 10px !important;
  }
}

.google-auth-button {
  width: 100px;
}

.google-auth-button button {
  height: 100px !important;
}
